// Libs
import { useQueries } from 'react-query';
// APIs
import apiFetch from '../fetcher';
import { QUERY_KEYS } from './QUERY_KEYS';
import { API_URL } from '../apiURLs';
import { useProxyQuery } from './useProxyQuery';

export const useFetchAllCameras = (search = '', multi_words, options) => {
  return useProxyQuery(
    [QUERY_KEYS.CAMERAS],
    () => apiFetch(API_URL.getCameraList(search), multi_words),
    options
  );
};

export const useFetchCameraById = (id, options) =>
  useProxyQuery(
    [QUERY_KEYS.CAMERAS, id],
    () => apiFetch(API_URL.getCameraById(id)),
    options
  );

export const useFetchCameraLiveImage = (id, server = 'va1', server_type = 'VA', width, options, body) => {
  return useProxyQuery(
    [QUERY_KEYS.LIVE_IMAGE, id, server, width],
    () => apiFetch(API_URL.getCameraLiveImage(id, server, server_type, width), body),
    options
  );
};

export const useFetchCameraFootfallImage = (id, options, body) => {
  return useProxyQuery(
    [QUERY_KEYS.FOOTFALL_IMAGE, id],
    () => apiFetch(API_URL.getCameraFootfallImage(id), body),
    options
  );
};

export const useFetchCamerasLiveImages = (ids = [], server = 'va1', server_type = 'VA', width, options) => {
  return useQueries(
    ids?.map(id => ({
      queryKey: [QUERY_KEYS.LIVE_IMAGES, id, server, width],
      queryFn: () => apiFetch(API_URL.getCameraLiveImage(id, server, server_type, width)),
      ...options,
    }))
  );
};

export const useFetchCamerasAssist = (ids = [], options) => {
  return useQueries(
    ids?.map(id => ({
      queryKey: [QUERY_KEYS.GET_CAMERA_ASSIST, id],
      queryFn: () => apiFetch(API_URL.getCameraAssist(id)),
      ...options,
    }))
  );
};

export const useUpdateCameraAssist = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.UPDATE_CAMERA_ASSIST, id],
    () => apiFetch(API_URL.updateCameraAssist(id)),
    options
  );
};

export const useUpdatePlaneCalibration = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.UPDATE_PLANE_CALIBRATION, id],
    () => apiFetch(API_URL.updatePlaneCalibration(id)),
    options
  );
};

export const useFetchPlaneCalibration = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.GET_PLANE_CALIBRATION, id],
    () => apiFetch(API_URL.getPlaneLatestCalibration(id)),
    options
  );
};

export const useFetchCameraPolygons = (category, cameraId, options) => {
  return useProxyQuery(
    [category, cameraId],
    () => apiFetch(API_URL.getCameraPolygons(category, cameraId)),
    options
  );
};

export const useFetchProjectionPolygons = (category, cameraId, options) => {
  return useProxyQuery(
    [category, cameraId],
    () => apiFetch(API_URL.getProjectionPolygons(category, cameraId)),
    options
  );
};

export const useFetchCamerasByLocation = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.LOCATION_CAMERAS, id],
    () => apiFetch(API_URL.getCamerasByLocation(id)),
    options
  );
};

export const useFetchCameraTemplateImage = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.CAMERA_TEMPLATE, id],
    () => apiFetch(API_URL.getCameraTemplateImage(id)),
    options
  );
};

export const useFetchCamerasListByLocation = (id, camera_view, options) => {
  return useProxyQuery(
    [QUERY_KEYS.CAMERAS_LIST, id, camera_view],
    () => apiFetch(API_URL.getCamerasListByLocation(id, camera_view)),
    options
  );
};

export const useFetchCamerasTemplateImages = (ids = [], options) => {
  return useQueries(
    ids?.map(id => ({
      queryKey: [QUERY_KEYS.CAMERA_TEMPLATE, id],
      queryFn: () => apiFetch(API_URL.getCameraTemplateImage(id)),
      ...options,
    }))
  );
};

export const useFetchCameraReferenceImage = (id, options, body) => {
  return useProxyQuery(
    [QUERY_KEYS.REFERENCE_IMAGE, id],
    () => apiFetch(API_URL.getLatestReferenceImage(id), body),
    options
  );
};

export const useFetchCamerasReferenceImages = (ids = [], options) => {
  return useQueries(
    ids?.map(id => ({
      queryKey: [QUERY_KEYS.REFERENCE_IMAGES, id],
      queryFn: () => apiFetch(API_URL.getLatestReferenceImage(id)),
      ...options,
    }))
  );
};
